const path = 'suppliers/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.patch(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const history = ({ pk, opt = {} }) =>
  axios.get(`getHistorySupplier/${pk}/`, opt)
const excel = ({ pk, params, opt = {} }) =>
  axios.get(`getSuppliersExcel/${pk}/${params}`, {
    ...opt,
    responseType: 'blob'
  })

const path2 = 'supplier-contact/'
const contact = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  history,
  contact,
  excel
}
