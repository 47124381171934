import axios from 'axios'
// import all services
import auth from './auth'
import backup from './backup'
import chat from './chat'
import company from './company'
import country from './country'
import currency from './currency'
import customer from './customer'
import customerOrigin from './customerOrigin'
import customersReport from './customer'
import dashboard from './dashboard'
import department from './department'
import email from './email'
import equipment from './equipment'
import equipmentsReport from './equipment'
import event from './event'
import expense from './expense'
import expensesReport from './expense'
import formula from './formula'
import group from './group'
import inventory from './inventory'
import invoice from './invoice'
import invoicesReport from './invoice'
import loginCarrousel from './loginCarrousel'
import mainEmail from './mainEmail'
import maintenance from './maintenance'
import matrix from './matrix'
import movement from './movement'
import movementsReport from './movement'
import notifications from './notifications'
import product from './product'
import purchase from './purchase'
import purchaseDocument from './purchaseDocument'
import quotation from './quotation'
import role from './role'
import sale from './sale'
import salesReport from './sale'
import sequence from './sequence'
import sold from './sold'
import subDomain from './subDomain'
import suborder from './suborder'
import supplier from './supplier'
import suppliersReport from './supplier'
import tax from './tax'
import terms from './terms'
import user from './user'

// Declarations de global info for axios
const baseURL =
  process.env.NODE_ENV === 'production' ||
  (process.env.VUE_APP_DEBUG_SERVER !== undefined &&
    process.env.VUE_APP_DEBUG_SERVER === true)
    ? `${process.env.VUE_APP_SERVER_API_TYPE}${
        !(
          process.env.VUE_APP_DEBUG_SERVER !== undefined &&
          process.env.VUE_APP_DEBUG_SERVER === true
        )
          ? location.hostname
          : 'eprintpos.com'
      }${process.env.VUE_APP_SERVER_API_PORT}${
        process.env.VUE_APP_SERVER_API_ROUTE
      }`
    : process.env.VUE_APP_API_URL
window.axios = axios
window.axios.defaults.baseURL = baseURL

// Validation for get token for first load
if (
  localStorage.getItem('token') != '' &&
  localStorage.getItem('token') != null
) {
  window.axios.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token')
}

const api = {
  auth,
  backup,
  chat,
  company,
  country,
  currency,
  customer,
  customerOrigin,
  customersReport,
  dashboard,
  department,
  email,
  equipment,
  equipmentsReport,
  event,
  expense,
  expensesReport,
  formula,
  group,
  inventory,
  invoice,
  invoicesReport,
  loginCarrousel,
  mainEmail,
  maintenance,
  matrix,
  movement,
  movementsReport,
  notifications,
  product,
  purchase,
  purchaseDocument,
  quotation,
  role,
  sale,
  salesReport,
  sequence,
  sold,
  subDomain,
  suborder,
  supplier,
  suppliersReport,
  tax,
  terms,
  user
}

export { baseURL, api }
