<template>
  <v-container fluid>
    <!--
      Alerta en la orden de venta, para el caso en que las cantidades
      estén fuera del rango de la matriz del producto
    -->
    <v-row
      v-if="
        subOrder !== null &&
        matrix !== undefined &&
        valuesMatrix[2] == -1 &&
        detail !== null
      "
      class="my-0"
    >
      <v-col cols="12">
        <v-alert type="info" outlined color="error">
          <span class="font-weight-bold">
            min: {{ valuesMatrix[0] === undefined ? '' : valuesMatrix[0] }}
          </span>
          <span class="font-weight-bold">
            max: {{ valuesMatrix[1] === undefined ? '' : valuesMatrix[1] }}
          </span>
        </v-alert>
      </v-col>
    </v-row>

    <!-- ????? -->
    <v-row v-if="valuesMatrix[2] >= 0 && subOrder !== null" class="my-0">
      <!--
        Validación de campo de cantidades para que se ajuste a
        los rangos de cantidades de la matriz en la orden de venta
      -->
      <v-col cols="12" md>
        <ValidationProvider
          vid="amount"
          :name="$tc('amount', 1)"
          :rules="{
            required: true,
            double: 2,
            min_value: valuesMatrix[0] === undefined ? false : valuesMatrix[0],
            max_value:
              valuesMatrix[1] === undefined || valuesMatrix[1] === 0
                ? false
                : valuesMatrix[1]
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            color="secondary"
            outlined
            type="number"
            :error-messages="errors[0]"
            :label="$tc('amount', 1)"
            @change="postString"
            v-model="quantity"
          />
        </ValidationProvider>
      </v-col>

      <!-- Tabs -->
      <v-col v-if="sides.length > 0 && valuesMatrix[2] > -1" cols="6" md>
        <ValidationProvider
          vid="side"
          :name="$tc('side', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            color="primary"
            outlined
            :disabled="
              subOrder.orderMain !== 'draft' && subOrder.orderMain !== 'quote'
            "
            :error-messages="errors[0]"
            :items="sides"
            :label="$t('side')"
            @change="postString"
            v-model="side"
          ></v-select>
        </ValidationProvider>
      </v-col>

      <!-- Columnas -->
      <v-col v-if="valuesMatrix[2] > -1 || true" cols="6" md>
        <ValidationProvider
          vid="col"
          :name="$tc('value', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            color="primary"
            outlined
            :disabled="
              subOrder.orderMain !== 'draft' && subOrder.orderMain !== 'quote'
            "
            :error-messages="errors[0]"
            :items="columns"
            :label="$tc('value', 1)"
            @change="postString"
            v-model="column"
          ></v-select>
        </ValidationProvider>
      </v-col>

      <!-- Costo -->
      <v-col v-if="valuesMatrix[2] > -1 || true" cols="6" md>
        <v-text-field
          outlined
          disabled
          :label="$tc('cost', 1)"
          :value="valuesMatrix[3]"
        />
      </v-col>

      <!-- Precio -->
      <v-col v-if="valuesMatrix[2] > -1 || true" cols="6" md>
        <v-text-field
          outlined
          disabled
          :label="$tc('price', 0)"
          :value="valuesMatrix[2]"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="valuesMatrix[2] >= 0 && subOrder !== null && showSubtotal"
      class="my-0 justify-content-end"
    >
      <!-- Subtotal -->
      <v-col v-if="valuesMatrix[2] > -1 || true" cols="12" md="3">
        <v-alert type="info" outlined color="primary">
          <i-money
            :value="valuesMatrix[2] * qty"
            mode="text"
            :label="$t('subtotal')"
          />
        </v-alert>
      </v-col>
    </v-row>

    <!-- Lista de matrices con costos y precios por cada tipo de cliente -->
    <v-row v-if="showMatrix" class="my-0">
      <v-col v-if="hasMatrix" cols="11">
        <v-select
          v-if="view.matrixTemplate && view.level"
          autocomplete="off"
          color="secondary"
          hide-details
          key="level"
          name="level"
          outlined
          :items="levels"
          item-text="name"
          item-value="pk"
          :label="$tc('level', 1)"
          @change="setOtherMatrix"
          v-model="level"
        />
        <br v-if="level === null" />
      </v-col>

      <!-- Lista de plantillas de matriz -->
      <v-col cols="11" v-else>
        <v-select
          v-if="
            view.matrixTemplate && new_template == null && main_matrix == null
          "
          autocomplete="off"
          color="secondary"
          hide-details
          key="matrix"
          name="matrix"
          outlined
          :clearable="matrixs.length > 10"
          :items="matrixs"
          item-text="name"
          item-value="pk"
          :label="$tc('matrix', 1)"
          @change="setTemplate"
          :append-icon="matrixs.length > 10 ? 'fa-search' : ''"
          v-model="matrix"
        />
        <br v-if="matrix === null" />
      </v-col>

      <!-- Botón de guardado -->
      <v-col class="align-self-center pt-0" cols="1">
        <i-btn
          color="primary"
          icon="fa-save"
          outlined
          :title="$tc('save', 1)"
          @click="submit"
        ></i-btn>
      </v-col>

      <!-- Creación de matriz -->
      <v-col v-if="!hasMatrix || level > 0" cols="12">
        <CreateMatrix
          ref="product_matrix"
          :detailId="detailId"
          :levelId="level"
          :new_template="new_template"
          :productId="productId"
          :subdetailId="subdetailId"
          @hasMatrix="hasMatrix = $event"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CreateMatrix from '../../pages/matrix/form.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    CreateMatrix
  },
  props: {
    subOrder: {
      type: Object,
      default: null
    },
    detail: {
      type: [Object, Array],
      default: null
    },
    type_customer: {
      type: [Number, String]
    },
    productId: {
      type: [String, Number],
      default: null
    },
    price: {
      type: [String, Number],
      default: 0
    },
    qty: {
      type: [Number, String],
      default: 0
    },
    detailId: {
      type: [String, Number],
      default: null
    },
    subdetailId: {
      type: [String, Number],
      default: null
    },
    new_template: {
      type: Number,
      default: null
    },
    main_matrix: {
      type: Number,
      default: null
    },
    priceString: {
      type: String,
      default: ''
    },
    matrixUse: {
      type: String,
      required: true
    },
    showMatrix: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    showSubtotal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      edit: false,
      quantity: 0,
      sides: [],
      side: this.priceString.split('*')[3],
      columns: [],
      column: this.priceString.split('*')[2],
      levels: [],
      level: null,
      matrixs: [],
      matrix: null,
      hasMatrix: false,
      matrixProduct: false
    }
  },
  methods: {
    /**
     * seeMatrix
     * Método para asignar la cantidad de la orden de venta
     * y llamar la funcion de getMatrix
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async seeMatrix() {
      if (this.subOrder !== null) {
        this.quantity = this.subOrder.quantity
      } else {
        this.quantity = this.qty
      }
      if (this.productId !== null) {
        this.getMatrix('product')
      } else {
        this.getMatrix('process')
      }
    },
    /**
     * getMatrix
     * Método para consultar la matriz según su tipo
     * con sus costos y precios
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getMatrix(type) {
      const params = {
        product_id: this.productId,
        level_id:
          this.type_customer === undefined ? this.level : this.type_customer,
        detail_id: this.detailId,
        subdetail_id: this.subdetailId !== '' ? this.subdetailId : null
      }
      if (type === 'product') {
        await this.$api.matrix
          .list({
            opt: {
              params: params
            }
          })
          .then((response) => {
            this.setMatrix(response)
          })
      } else {
        await this.$api.matrix.matrixprocess
          .list({
            opt: {
              params: params
            }
          })
          .then((response) => {
            this.setMatrix(response)
          })
      }
    },
    /**
     * setMatrix
     * Método que envia los costos y los precios de la matriz
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async setMatrix(response) {
      let matrix = response.data[0]
      if (matrix !== undefined && matrix !== null) {
        this.hasMatrix = true
        this.sides = matrix.rows_title
        this.columns = matrix.matrix_values[1].col_title
        if (this.$refs['product_matrix']) {
          this.$refs.product_matrix.setValues(matrix, true)
        }
        this.matrix = matrix
      }
      if (this.subOrder !== null) {
        this.matrix = matrix
      }
    },
    /**
     * submit
     * Método que guardar la matriz con costos y precios y
     * activar el producto donde fue seleccionada
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    submit() {
      if (this.$refs['product_matrix'] !== undefined) {
        this.$refs['product_matrix'].submit(true)
        this.getLevels()
        this.$emit('update:active', true)
      } else {
        this.$toast.error(`${this.$tc('required', 1)}`)
      }
    },
    /**
     * postString
     * Método pasar como cadena los valores de la matriz
     * en la orden de venta
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async postString() {
      if (
        this.subOrder.price_type === 'matrix' ||
        this.detail.price_type === 'matrix' ||
        this.detail.pk !== ''
      ) {
        let post =
          this.side !== undefined
            ? this.valuesMatrix[2] +
              '*' +
              this.valuesMatrix[3] +
              '*' +
              this.column +
              '*' +
              this.side
            : this.valuesMatrix[2] +
              '*' +
              this.valuesMatrix[3] +
              '*' +
              this.column
        if (this.detail !== null && this.detail !== undefined) {
          this.detail.priceString = post
          this.detail.price = this.valuesMatrix[2]
          this.$emit('change', this.valuesMatrix[2])
        } else {
          this.subOrder.priceString = post
          this.subOrder.price = this.valuesMatrix[2]
          this.subOrder.quantity = this.quantity
        }
      }
    },
    /**
     * seeMatrixTemplate
     * Método que consulta una plantilla de matriz
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async seeMatrixTemplate(pk, productId) {
      let matrix = await this.$api.matrix.template.show({ pk: pk })
      matrix = matrix.data
      if (
        matrix &&
        matrix.matrix_template_values &&
        this.$refs['product_matrix'] != undefined
      ) {
        this.$refs['product_matrix'].setTemplate(matrix, productId)
        this.getLevels()
      }
    },
    /**
     * getLevels
     * Método que consulta los precios para cada tipo de cliente
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getLevels() {
      const level = await this.$api.customer.level.list({
        opt: {
          params: {
            company_id: this.company.pk
          }
        }
      })
      this.levels = level.data.results
    },
    /**
     * getMatrixProduct
     * Método que consulta la matriz principal de un producto
     * con tipo de precio matriz
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getMatrixProduct() {
      const product = await this.$api.product.show({
        pk: this.productId
      })
      if (product.data.price_type === 'matrix') {
        await this.$api.matrix
          .list({
            opt: {
              params: {
                product_id: this.productId,
                level_id: null,
                detail_id: null
              }
            }
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.matrixProduct = response.data[0].detail === null
            }
          })
      } else {
        this.matrixProduct = true
      }
    },
    /**
     * getMatrixDetail
     * Método que consulta las matrices tipo item o subitem,
     * de una plantilla de proceso productivo
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getMatrixDetail() {
      await this.$api.matrix.matrixprocess
        .list({
          opt: {
            params: {
              detail_id: this.detailId,
              subdetail_id: this.subdetailId !== '' ? this.subdetailId : null,
              level_id: null,
              detail_id: null
            }
          }
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.matrixProduct = response.data[0].detail === null
          }
        })
    },
    /**
     * setOtherMatrix
     * Método que envía los parámetros de la matriz
     * tipo detalle que se desea visualizar
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    setOtherMatrix() {
      this.seeMatrix(
        this.productId,
        this.type_customer === undefined ? this.level : this.type_customer,
        this.detailId,
        this.subdetailId
      )
    },
    /**
     * getMatrixTemplates
     * Método que consulta la lista de plantillas de matriz según su uso
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getMatrixTemplates() {
      const matrix = await this.$api.matrix.template.list({
        opt: {
          params: {
            matrix_use: this.matrixUse
          }
        }
      })
      this.matrixs = matrix.data.results
      this.seeMatrix()
    },
    setTemplate() {
      this.seeMatrixTemplate(
        this.main_matrix == null ? this.matrix : this.main_matrix,
        this.productId
      )
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      company: 'company/getCompanyData',
      isAdmin: 'session/isAdmin'
    }),
    view() {
      return {
        matrix: this.getPermissions([`matrix.view_matrix`]) || this.isAdmin,
        matrixTemplate:
          this.getPermissions([`matrix.view_matrixtemplate`]) || this.isAdmin,
        price:
          this.getPermissions([`product.view_productprices`]) || this.isAdmin,
        level: this.getPermissions([`base_config.view_level`]) || this.isAdmin
      }
    },
    valuesMatrix() {
      let position = []
      if (this.matrix !== null && this.column !== undefined) {
        let inxCol = this.columns.findIndex((item) => item === this.column)
        let rows = this.matrix.matrix_values[1].rows_title
        for (var item in rows) {
          let range =
            rows[item].split('-')[1] > 0
              ? rows[item].split('-')[1]
              : rows[item].split('-')[0]
          if (this.quantity <= Number(range) && position.length === 0) {
            position[0] = item
          }
        }
        let inxSide =
          this.sides.length === 0 || this.side === ''
            ? 0
            : this.sides.findIndex((item) => item === this.side)
        let values = []
        values[0] = Number(rows[0].split('-')[0]).toFixed(2)
        values[1] =
          Number(rows[rows.length - 1].split('-')[1]).toFixed(2) > 0
            ? Number(rows[rows.length - 1].split('-')[1]).toFixed(2)
            : Number(rows[rows.length - 1].split('-')[0]).toFixed(2)
        if (position[0] === undefined) {
          return [values[0], values[1], -1, -1]
        }
        if (inxCol > -1) {
          values[2] =
            this.matrix.matrix_values[inxSide * 2 + 1].values[position[0]][
              inxCol
            ]
          values[3] =
            this.matrix.matrix_values[inxSide * 2].values[position[0]][inxCol]
        }
        return values
      } else {
        return [0, 0, 0, 0]
      }
    }
  },
  mounted() {
    if (this.matrix === null) {
      this.getMatrixTemplates()
      this.seeMatrix()
      this.getLevels()
    }
    if (this.productId !== null) {
      this.getMatrixProduct()
      if (this.main_matrix != null) {
        this.setTemplate(this.main_matrix, this.productId)
      }
    } else {
      this.getMatrixDetail()
    }
    if (this.new_template != null) {
      this.seeMatrixTemplate(this.new_template, this.productId)
    }
    if (this.matrix != null) {
      this.seeMatrixTemplate(this.matrix.pk, this.productId)
    }
  }
}
</script>
