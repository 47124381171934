<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="detailsForm">
    <form @submit.prevent="handleSubmit(submit)">
      <!-- Detalles de producción seleccionables -->
      <v-row v-if="process.details.length > 0" class="my-0">
        <v-col
          v-for="(item, key) in process.details"
          :key="key.pk"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <!-- Subdetalles de producción -->
          <v-select
            v-if="item.subitems"
            item-text="name"
            item-value="pk"
            outlined
            :disabled="readonly || belongsToOrder(item.pk, item.multi_choice)"
            :items="filterSubItems(item.pk)"
            :key="'select' + item.pk"
            :label="item.name"
            @change="openDetail('create', process.subdetail, item.pk)"
            v-model="process.subdetail"
          ></v-select>

          <!-- Detalles de producción -->
          <v-checkbox
            v-else
            class="v-switch mt-2"
            color="primary"
            name="hidden"
            :disabled="readonly || belongsToOrder(item.pk)"
            :input-value="belongsToOrder(item.pk)"
            :key="'switch' + item.pk"
            :label="item.name"
            @click="openDetail('create', item)"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- Formulario de detalle de producción detallado -->
      <i-modal @submit="submit(modalAction)" v-model="dialog">
        <!-- Título y oculto -->
        <p class="details-by-order--title mb-0">{{ saleDetail.name }}</p>
        <div class="w-25">
          <v-switch
            class="v-switch mt-0"
            color="primary"
            key="hidden-input"
            name="hidden"
            :label="$tc('hide')"
            v-model="saleDetail.hidden"
          ></v-switch>
        </div>
        <p class="text-small">saleDetail: {{ saleDetail }}</p>
        <p class="text-small">
          Have dimentions? {{ saleDetail.have_dimensions }}
        </p>
        <p class="text-small">saleDetail.cost: {{ saleDetail.cost }}</p>
        <p class="text-small">
          saleDetail.price: {{ saleDetail.price || saleDetail.cost }}
        </p>
        <p class="text-small">rounded: {{ rounded }}</p>

        <!-- Detalles del item | Base -->
        <div v-if="saleDetail.price_type === 'base'">
          <i-money :label="$tc('price', 0)" v-model="saleDetail.price" />
        </div>

        <!-- Detalles del item | Fórmula -->
        <div v-if="saleDetail.price_type === 'formula'">
          <i-formula
            is_detail
            :key="saleDetail.pk"
            :cost="saleDetail.cost || 0"
            :defaultMeasure="defaultMeasure"
            :dimensions="saleDetail.have_dimensions ? dimensions : {}"
            :formula="saleDetail.formula"
            :measure="saleDetail.have_dimensions ? measure : ''"
            :price="saleDetail.price || saleDetail.cost"
            :qty="qty"
            :readonly="readonly"
            :rootDimensions="saleDetail.pk ? {} : saleDetail.dimensions"
            :rounded="rounded"
            @change="saleDetail.price = $event"
            @changedimensions="saleDetail.dimensions = $event"
            v-model="saleDetail.priceString"
          />
        </div>
      </i-modal>

      <!-- Formulario de los detalles de producción ya asociados en la orden -->
      <h2 v-if="process.selected.length > 0">
        {{ $t('details_production') }} | {{ $t('cumulative') }}
      </h2>
      <div
        v-for="(item, index) in process.selected"
        :key="`${'fields'}-${index}`"
      >
        <!-- Propuesta: Título y botones -->
        <div class="text-sumatory d-flex align-items-center g-2 mb-2">
          <p class="mb-0">
            &bull;
            {{ item.name }}
          </p>
          <span class="dotted-span"></span>
          <div>
            <i-money
              v-if="item.price_type !== 'bool'"
              mode="text"
              :value="item.price"
            />
            <v-btn
              class="px-2"
              color="primary"
              icon
              :disabled="item.price_type === 'bool'"
              @click="openDetail('edit', item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="px-2" color="aux-text" icon @click="hideDetail(item)">
              <v-icon v-if="item.hidden" small>mdi-eye-off-outline</v-icon>
              <v-icon v-else small>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn class="px-2" color="error" icon @click="remove(item)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="details-by-order--item d-none">
          <!-- Título y oculto -->
          <div class="d-flex w-100 px-3 flex-row justify-content-between">
            <div>
              <p class="details-by-order--title mb-0">{{ item.name }}</p>
              <v-switch
                class="v-switch mt-0"
                color="primary"
                key="hidden-input"
                name="hidden"
                :label="$tc('hide')"
                v-model="item.hidden"
              ></v-switch>
            </div>
            <div class="d-flex g-2">
              <!-- Botones -->
              <v-btn color="error" small @click="remove(item)">
                <v-icon class="px-2" small>fa-trash</v-icon>
              </v-btn>
              <v-btn color="primary" small @click="oldSubmit(item)">
                <v-icon class="px-2" small>fa-save</v-icon>
              </v-btn>
            </div>
          </div>

          <!-- Detalles del item | Matriz -->
          <div v-if="item.price_type === 'matrix'">
            <i-matrix
              :type_customer="subOrder.level"
              :productId="subOrder.product"
              :priceString="item.priceString"
              :price="item.price"
              :qty="qty"
              :subOrder="subOrder"
              :detail.sync="process.selected"
              :showMatrix="false"
              :matrixUse="'details'"
              :showSubtotal="false"
              @change="item.price = $event"
            >
            </i-matrix>
          </div>

          <!-- Subtotal del item -->
          <div>
            <v-alert text type="info" color="primary">
              <i-money
                mode="text"
                :label="$t('subtotal')"
                :value="subtotal(item)"
              />
            </v-alert>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    order: Number,
    readonly: {
      type: Boolean,
      default: false
    },
    measure: String,
    defaultMeasure: String,
    rounded: Boolean,
    qty: [Number, String],
    creatingDetail: {
      default: 1,
      type: Number
    },
    reload: {
      type: Boolean,
      default: false
    },
    subOrder: {
      type: Object
    },
    type_customer: {
      type: Number,
      default: 0
    },
    dimensions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      active: null,
      loading: false,
      load: false,
      title: '',
      val: 1,
      dialog: false,
      process: {
        selected: [],
        details: [],
        subdetails: [],
        subdetail: null
      },
      saleDetail: {
        subOrder: '',
        pk: '',
        detail: '',
        subdetail: null,
        dimensions: {},
        priceString: '',
        formula: '',
        main_matrix: '',
        hidden: false,
        price: '',
        have_dimensions: ''
      },
      modalAction: 'edit'
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    orderDetails() {
      return {
        add: this.getPermissions([`sales.add_orderdetails`]),
        edit: this.getPermissions([`sales.change_orderdetails`]),
        delete: this.getPermissions([`sales.delete_orderdetails`])
      }
    }
  },
  watch: {
    reload(val) {
      if (val) {
        this.getSavedItems()
        this.$emit('update:reload', false)
      }
    },
    order: {
      immediate: true,
      handler() {
        this.getSavedItems()
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    getNameSelect(item) {
      return `${item.name} ${
        this.item.typeDetail === 'deliverytime' ? this.$t('days') : ''
      }`
    },
    /**
     * getSavedItems
     * Método para consultar los procesos de producción (items) asociados a una
     * suborden de compra. En este método es donde se emiten los datos del
     * v-model detailPrices en DialogProduct
     *
     * @var process.selected: Items y subitems guardados en la suborden
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getSavedItems() {
      let subtotal = []
      let response = await this.$api.suborder.production.show({
        pk: this.subOrder.pk,
        opt: {
          params: {
            subOrder: true
          }
        }
      })
      this.process.selected = response.data
      subtotal = response.data
      // Este for filtraba pero ahora no se necesita
      // for (let item of this.process.selected) {
      //   if (
      //     item.price > 0 &&
      //     (item.priceString != '' ||
      //       item.have_dimensions ||
      //       item.price_type === 'base')
      //   ) {
      //     subtotal = subtotal.concat(item)
      //   }
      // }
      this.$emit('input', subtotal)
    },
    /**
     * getItems
     * Método para consultar los procesos de producción de un producto
     * y sus subprocesos (también llamados items y subitems respectivamente).
     *
     * @var process.details: Items del template del producto (es la copia del
     *                       template que se puede haber modificado - sin hijos)
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getItems() {
      let subOrderProduct = this.subOrder.product
      try {
        let response = await this.$api.product.details.list({
          opt: {
            params: {
              product: subOrderProduct
            }
          }
        })
        this.process.details = response.data
        this.process.details.filter((item) => {
          if (item.subitems === true) {
            this.process.subdetails = [] // evita el overflow del copiado
            this.getSubItems(item.pk)
          }
        })
      } catch (error) {
        console.error('Error en la consulta getItems')
        console.error(error)
      }
    },
    /**
     * getSubItems
     * Método para consultar los subprocesos de producción (subitems) del
     * proceso de producción (item) al que pertenecen.
     *
     * @param itemPK: id del Item
     * @var process.subdetails: Subitems de los items del template del producto
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getSubItems(itemPK) {
      let subitems = []
      try {
        let response = await this.$api.product.subdetails.list({
          opt: {
            params: {
              detail: itemPK
            }
          }
        })
        subitems = response.data
        this.process.subdetails = [...this.process.subdetails, ...subitems]
      } catch (error) {
        console.error('Error en la consulta de getSubItems')
        console.error(error)
      }
    },
    /**
     * filterSubItems
     * Método para filtrar los subitems de procesos de producción por pk
     * del item al que pertenecen
     *
     * @param itemPK: id del Item
     * @return filter: Array de subitems que le pertenecen al itemPK
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    filterSubItems(itemPK) {
      let filter = []
      filter = this.process.subdetails.filter((item) => item.item == itemPK)
      return filter
    },
    /**
     * getSingleSubitem
     * Método para buscar un subitem de procesos de producción específico
     *
     * @param itemPK: id del Item
     * @return arrayDeCositas: Array de subitems que le pertenecen al itemPK
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    getSingleSubitem(itemPK, subItemPK) {
      return this.process.subdetails.find(
        (item) => item.item === itemPK && item.pk === subItemPK
      )
    },
    /**
     * belongsToOrder
     * Método que comprueba si el proceso/subproceso de producción ya pertenece
     * a la suborden
     *
     * @param {number} itemPK     ID del item
     * @param {boolean} itemMulti Se puede seleccionar más de una opción
     *
     * @return {boolean}
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    belongsToOrder(itemPK, itemMulti = false) {
      if (itemPK && this.process.selected.length && !itemMulti) {
        return this.process.selected.some((event) => event.detail == itemPK)
      }
      return false
    },
    /**
     * remove
     * Método que elimina un detalle de producción que pertenezca a una orden.
     *
     * @param {object} item - Objecto del detalle de producción a eliminar
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    remove(item) {
      this.title = item.name
      this.showConfirmation({
        show: true,
        model: this.$tc(this.title, 1),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc(this.title, 1),
          action: this.$tc('delete', 2)
        })}`,
        confirm: async () => {
          await this.$api.suborder.production.remove({ pk: item.pk })
          this.getItems()
          this.getSavedItems()
          this.$emit('update:reload', true)
          this.process.subdetail = null
        }
      })
    },
    /**
     * subtotal
     * Calcula el subtotal de cada detalle de producción
     *
     * @param {Object} item
     * @return {Number}
     */
    subtotal(item) {
      if (item.is_multipliable) {
        return parseFloat(item.price) * parseFloat(this.qty)
      }
      return parseFloat(item.price)
    },
    /**
     * openDetail
     * Método gestionar lo que pasa con los detalles de producción.
     * - Si es un detalle de producción se
     *
     * @param action {create, edit} Define la acción que hará la modal
     * @param item                  Datos del proceso o subproceso de producción
     * @param itemPK                ID del registro en caso que sea un
     *                              subproceso de producción
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async openDetail(action, item, itemPK = '') {
      // Inicializaciones importantes
      let price_type = ''
      this.modalAction = action

      // Inicialización del saleDetail al CREATE
      if (action === 'create') {
        if (itemPK) {
          let selectedSubitem = await this.getSingleSubitem(itemPK, item)
          this.saleDetail = selectedSubitem
          this.saleDetail.subdetail = selectedSubitem.pk
          this.saleDetail.detail = selectedSubitem.item
          this.saleDetail.name = selectedSubitem.name
          price_type = selectedSubitem.price_type
        } else {
          this.saleDetail = item
          this.saleDetail.subdetail = null
          this.saleDetail.detail = item.pk
          this.saleDetail.name = item.name
          price_type = item.price_type
        }
      }

      // Inicialización del saleDetail al EDIT
      if (action === 'edit') {
        this.saleDetail = item
        price_type = item.price_type
      }

      // Número de la orden
      this.saleDetail.subOrder = this.subOrder.pk

      // Detalles de producción tipo BOOL se guardan sin abrir la modal
      if (price_type === 'bool') {
        this.submit(this.modalAction)
      }

      // CREAR para todos los demás casos
      if (action === 'create' && price_type !== 'bool') {
        if (price_type === 'base') {
          this.submit(this.modalAction)
        } else {
          this.dialog = true
        }
      }

      // EDITAR para todos los demás casos
      if (action === 'edit' && price_type !== 'bool') {
        this.dialog = true
      }
    },
    /**
     * submit
     * Método para determinar si al enviar el formulario de la modal, este debe
     * crear un nuevo registro o editar uno exisitente.
     *
     * @param {String} action   Determina la acción que realizará. Tiene dos
     *                          modos posibles, -create- y -edit-, donde edit
     *                          es el modo por defecto en caso de no enviar el
     *                          parámetro.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    submit(action = 'edit') {
      if (action === 'create') {
        this.createDetail()
      }
      if (action === 'edit') {
        this.editDetail()
      }
    },
    /**
     * createDetail
     * Método para guardar el detalle/subdetalle de producción en la orden de
     * venta.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async createDetail() {
      try {
        await this.$api.suborder.production.create({
          form: this.saleDetail,
          opt: {
            params: {
              level: this.subOrder.level
            }
          }
        })
        this.$toast.success(
          `${this.$tc(this.title, 1)} ${this.$tc(
            this.saleDetail.pk !== undefined ? 'edited' : 'created',
            2
          )}`
        )
      } catch (error) {
        console.error('Error en createDetail')
        console.error(error)
      } finally {
        this.getSavedItems()
        this.dialog = false
      }
    },
    /**
     * editDetail
     * Método para modificar un detalle de producción ya asociado a la orden de
     * venta.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async editDetail() {
      try {
        await this.$api.suborder.production.edit({
          pk: this.saleDetail.pk,
          form: this.saleDetail
        })
        this.$toast.success(
          `${this.$tc('details_production', 1)} ${this.$tc('edited', 2)}`
        )
      } catch (error) {
        console.error('Error en el submit del detailsByOrder')
        console.error(error)
      } finally {
        this.getSavedItems()
        this.dialog = false
      }
    },
    /**
     * hideDetail
     * Método para ocultar/mostrar un detalle de producción ya asociado a la
     * orden de venta
     * @param item    Datos del detalle o subdetalle de producción ya asociado
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async hideDetail(item) {
      item.hidden = !item.hidden || false
      try {
        await this.$api.suborder.production.edit({
          pk: item.pk,
          form: item
        })
        this.$toast.success(
          `${this.$tc('details_production', 1)} ${this.$tc('edited', 2)}`
        )
      } catch (error) {
        console.error('Error en el submit del hideDetail')
        console.error(error)
      } finally {
        this.getSavedItems()
        this.dialog = false
      }
    },
    // Este es el submit anterior, con este si guardaba el precio entonces
    // lo tengo aquí de referencia por ahora, borrar cuando borre la caja de
    // los formularios de items/subitems
    async oldSubmit(item) {
      try {
        this.load = true
        await this.$api.suborder.production.edit({
          pk: item.pk,
          form: item
        })
        this.$toast.success(
          `${this.$tc('details_production', 1)} ${this.$tc('edited', 2)}`
        )
        this.getSavedItems()
      } catch (error) {
        console.error('Error en el submit del detailsByOrder')
        console.error(error)
      } finally {
        this.load = false
      }
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.title || !val) {
          this.getItems()
          this.getSavedItems()
          this.deleting = false
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  mounted() {
    this.getItems()
    this.getSavedItems()
  },
  beforeDestroy() {
    this.unwatch()
  }
}
</script>
<style lang="sass">
.details-by-order--title
  // Título de cada detalle de producción
  font-size: 1rem
  line-height: 1.3rem
  font-weight: bolder
  @media (width >= 600px)
    font-size: 1.25rem
    line-height: 2rem
.details-by-order--item
  // Container de cada detalle de producción
  padding: 12px
  border-radius: 8px
  margin-bottom: 12px
  display: flex
  flex-direction: column
  height: fit-content

.details-by-order--header
  // Header de cada detalle de producción
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center

.theme--light
  .dialog-product--modal-second-step
    .details-by-order--item
      background-color: rgb(0 0 0 / 5%)
      .details-by-order--title
        color: #616161

.theme--dark
  .dialog-product--modal-second-step
    .details-by-order--item
      background-color: rgb(255 255 255 / 5%)
      .details-by-order--title
        color: #e0e0e0
</style>
