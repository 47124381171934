<template>
  <div>
    <v-menu open-on-click bottom offset-y>
      <!-- Activador del menú, que muestra la campana de notificaciones -->
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          offset-x="30"
          offset-y="10"
          color="secondary"
          bottom
          :content="
            notifications.length == ''
              ? '0' // Muestra '0' si no hay notificaciones
              : notifications.length > 99
              ? '+99' // Muestra '+99' si hay más de 99 notificaciones
              : notifications.length
          "
        >
          <v-btn dark text v-bind="attrs" v-on="on">
            <v-img
              @click="getDesignerNotification"
              :src="require('@/assets/color-icons/settings/users.png')"
              height="32"
              width="32"
            ></v-img>
          </v-btn>
        </v-badge>
      </template>

      <!-- Contenedor para la lista de notificaciones con scroll -->
      <div
        style="
          max-height: 500px; /* Altura máxima del área de desplazamiento */
          overflow-y: auto; /* Habilitar scroll */
          width: 400px; /* Ancho fijo del contenedor */
        "
      >
        <!-- Lista de notificaciones si hay alguna -->
        <v-list
          v-if="notifications.length > 0"
          style="border: ridge 1px transparent; padding: 0px"
        >
          <v-list-item
            @click="open(item)"
            v-for="(item, index) in notifications"
            :key="index"
            text-color="primary"
            :style="{
              width: '100%',
              border: 'ridge 1px #DCDCDC',
              padding: '0px'
            }"
          >
            <v-col style="margin-bottom: 6px">
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-user
              </v-icon>
              <b style="font-size: 13px">{{
                $t('designerWorking').toUpperCase()
              }}</b>

              <!-- Diseñador -->
              <v-list-item-subtitle class="mb-1 mt-2" style="font-size: 13px">
                <b>{{ $t('designer') }}:</b>
                {{ item.user_data.name }}
              </v-list-item-subtitle>

              <!-- Sub-orden -->
              <v-list-item-subtitle
                class="text--secondary mb-1"
                style="font-size: 13px"
              >
                <b>{{ $tc('suborder', 1) }}:</b> {{ item.suborder_name }} -
                {{ item.suborder_number }}
              </v-list-item-subtitle>

              <!-- Estado de la Sub-orden -->
              <v-list-item-subtitle class="mb-1" style="font-size: 13px">
                <b>{{ $t('suborderstatus') }}:</b>
                {{ $t(item.suborder_status) }}
              </v-list-item-subtitle>

              <!-- Fecha de asignación -->
              <v-list-item-subtitle class="mb-1" style="font-size: 13px">
                <b>{{ $t('assignament_date') }}:</b>
                {{ formatDate(item.assignament_date) }}
              </v-list-item-subtitle>
            </v-col>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item>
            <v-list-item-title>{{ $t('no_notifications') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
    <!-- Diálogo de subOrden -->
    <dialprod ref="dialprod" @prod="getSuborder($event)"></dialprod>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialprod from '../sale/DialogProduct.vue'
export default {
  name: 'designer-notification',
  components: {
    dialprod
  },
  data() {
    return {
      notifications: [],
      current: 0,
      status: {},
      suborders: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      me: 'session/me'
    })
  },
  methods: {
    open(item) {
      this.current = item.suborder_id
      this.$refs.dialprod.open(item.suborder_id)
    },

    /**
     * formatDate
     *
     * Formatea la fecha según el formato configurado para la empresa.
     *
     * Ing. Rodrigo Boet (rudmanmrrod at gmail)
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    formatDate(date) {
      // Verifica si está configurado el formato de fecha en la empresa.
      if (this.company.format_date) {
        // Convierte el formato de la empresa a mayúsculas.
        let companyFormatDate = this.company.format_date.toUpperCase()

        // Si el formato de la empresa no incluye la hora, la concatenamos.
        if (!companyFormatDate.includes('HH:mm:ss')) {
          companyFormatDate += ' HH:mm:ss' // Agregamos la hora al formato.
        }

        // Formatea la fecha con el formato de la empresa (incluyendo la hora).
        return this.$moment(date).format(companyFormatDate)
      } else {
        // Formato de fecha y hora por defecto.
        return this.$moment(date).format('MM/DD/YYYY HH:mm:ss')
      }
    },

    async getDesignerNotification() {
      await this.$api.suborder.assigneduser
        .notificationByDesigner({ pk: this.company.pk })
        .then((response) => {
          this.notifications = response.data
        })
    },
    async getSuborder() {
      this.loading = true
      try {
        const response = await this.$api.sale.product.show({
          pk: this.current,
          opt: { params: { order: true } }
        })

        this.suborders = response.data

        await Promise.all(this.suborders.map(this.fetchStatusForSuborder))
        this.getDesignerNotification()
      } catch (error) {
        console.error('Error fetching suborders:', error)
      } finally {
        this.loading = false
      }
    },
    async fetchStatusForSuborder(suborder) {
      const templateId = suborder.template

      if (templateId > 0 && !this.postStatus[templateId]) {
        const stagesResponse = await this.$api.sale.status.list({
          opt: { params: { template: templateId } }
        })
        this.postStatus[templateId] = stagesResponse.data
      }

      const stages = this.postStatus[templateId] || []
      const statuses = stages
        .filter((stage) => stage.name !== 'preprocess')
        .map((stage) => this.calculateStageStatus(stage, suborder))

      this.$set(this.status, suborder.pk, statuses)
      this.$emit('update:miniSubOrder', suborder)
      this.$emit('done', suborder)
    },
    calculateStageStatus(stage, suborder) {
      let value = suborder.orderMain === 'finished' ? 100 : 0
      const subStatus = stage.substatusVals.find(
        (item) => item.pk === suborder.status
      )
      this.percent = suborder.orderStatus.includes('preprocess') ? 0 : 100

      if (subStatus) {
        this.root = subStatus.root
        this.percent = Math.round(
          ((subStatus.position - 2) * 100) /
            Math.max(stage.substatusVals.length - 2, 1)
        )
      }

      if (stage.pk > this.root) {
        this.percent = 0
      }

      const isPositioned = this.calculateStatusValue(stage, suborder)
      if (isPositioned) {
        value = Math.min(Math.max(value, 0), 100)
      }

      return {
        pk: stage.pk,
        name: stage.name,
        color: stage.color,
        value: Math.max(0, Math.min(this.percent, 100))
      }
    },
    calculateStatusValue(stage, suborder) {
      if (suborder.mainStatus === 'finished' || suborder.status === null) {
        return false
      }
      if (stage.pk === suborder.status || stage.substatusVals.length === 0) {
        return true
      }
      return stage.substatusVals.some(
        (substatus) => substatus.pk === suborder.status
      )
    }
  },
  mounted() {
    this.getDesignerNotification()
  }
}
</script>
