<template>
  <div v-if="permissions.view">
    <div
      v-if="select > 0 && totalItems > 0"
      class="progress-bar progress-bar-info"
      role="progressbar"
      :aria-valuenow="progress"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: progress + '%' }"
    >
      {{ progress }}%
    </div>
    <v-card
      :class="{
        'mx-0': $vuetify.breakpoint.xsOnly,
        'px-0': $vuetify.breakpoint.xsOnly
      }"
      elevation="0"
    >
      <v-toolbar v-if="!noTitle" color="secondary">
        <slot name="headerToolbar">
          <i-btn
            v-if="!$vuetify.breakpoint.xsOnly && !dontMode"
            :small="$vuetify.breakpoint.xsOnly"
            text
            :title="showMode"
            color="text-dark"
            @click="changeMode"
            :icon="
              showMode == 'list'
                ? 'fa-list'
                : showMode == 'simple'
                ? 'fa-grip-lines'
                : 'fa-grip-horizontal'
            "
          />
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="!noTitle" class="text-dark font-weight-bold">
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-divider v-if="!dontCreate" class="mx-4" inset vertical></v-divider>
          <i-search v-if="!dontSearch" v-model="search" @search="research()" />
          <i-btn
            v-if="!dontCreate && !readonly && permissions.create"
            text
            :small="$vuetify.breakpoint.smAndDown"
            icon="fa-plus"
            :title="$t('add', { value: title })"
            color="text-dark"
            @click="$emit('click:create')"
          >
          </i-btn>
          <slot name="extraToolbar" />
        </slot>
      </v-toolbar>
      <v-row class="m-0 py-5">
        <v-col
          :sm="showMode == 'grid' ? 4 : 12"
          :md="showMode == 'grid' ? 3 : 12"
          v-for="x in [1, 2, 3, 4, 5, 6, 7, 8]"
          v-show="loadingInternal"
          :key="'skeletonextra-' + x"
        >
          <v-skeleton-loader type="card" class="mx-auto" />
        </v-col>
        <v-col
          align="center"
          justify="center"
          class="display-1 font-weight-black grey--text"
          cols="12"
          v-show="itemsInternal.length === 0"
        >
          {{
            noDataText && noDataText !== ''
              ? noDataText
              : $vuetify.lang.t('$vuetify.noDataText')
          }}
          <br />
          <slot name="noData" :value="itemsInternal" />
        </v-col>
        <v-col cols="12">
          <slot name="alert" :value="itemsInternal" />
        </v-col>
        <v-col
          v-for="(item, i) in itemsInternal"
          :key="`card-${title}-${i}`"
          cols="12"
          :sm="showMode == 'grid' && min ? 2 : showMode == 'grid' ? 4 : 12"
          :md="showMode == 'grid' && min ? 2 : showMode == 'grid' ? 4 : 12"
        >
          <i-card
            :dontClose="
              !(
                (!dontRemove &&
                  item.canDelete &&
                  !readonly &&
                  !permissions.delete) ||
                (!dontRemove &&
                  item.canDelete == undefined &&
                  !item.changeDate &&
                  !readonly)
              )
            "
            @click="
              !readonly && permissions.edit ? $emit('click:edit', item) : ''
            "
            @remove="remove(item.pk || item.id)"
            :remove="permissions.delete"
            :active="item.active || !item[inactive]"
            :color="
              !dontColor
                ? item[color] ||
                  (selected !== null &&
                    selected.filter((event) => event[field] == item.pk).length >
                      0)
                : false
            "
          >
            <slot
              :name="`item`"
              :item="item"
              :showMode="showMode"
              :remove="remove"
            >
              <div
                v-for="({ text, value }, key) in headers"
                :key="`${title}-${i}-${key}`"
              >
                <slot v-if="value !== 'actions'" name="text" :text="text">
                  <span
                    v-if="text !== undefined"
                    class="primary--text font-weight-black"
                  >
                    {{ text }}:
                  </span>
                </slot>
                <slot :name="`item.${value}`" :item="item" :value="item[value]">
                  <span v-if="value !== 'actions'" class="primary--text">
                    {{ item[value] || '' }}
                  </span>
                </slot>
                <slot
                  name="otherBtn"
                  :item="item"
                  :value="item[value]"
                  :mode="showMode"
                  :remove="remove"
                />
              </div>
            </slot>
          </i-card>
        </v-col>
      </v-row>
      <v-row
        v-if="total"
        id="pagination"
        class="justify-content-center align-self-end pb-3 px-3"
      >
        <v-col
          v-show="itemsInternal.length > 0"
          class="pt-0"
          cols="12"
          sm="6"
          md="2"
        >
          <v-select
            :items="[5, 10, 15, 20, 40, 60]"
            v-model="perPageIn"
            :label="$vuetify.lang.t('$vuetify.dataTable.itemsPerPageText')"
          ></v-select>
        </v-col>
        <v-col v-show="itemsInternal.length > 0" cols="12" md="10">
          <v-pagination
            v-model="pageIn"
            :length="total"
            total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'i-card-list',
  props: {
    noDataText: {
      type: String,
      default: ''
    },
    app: {
      type: [String, Object],
      default: ''
    },
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
    api: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: [Array],
      default: () => []
    },
    method: {
      type: String,
      default: 'list'
    },
    removeMethod: {
      type: String,
      default: 'remove'
    },
    dontRemove: {
      type: Boolean,
      required: false
    },
    simple: {
      type: Boolean,
      required: false
    },
    dontEdit: {
      type: Boolean,
      required: false
    },
    dontCreate: {
      type: Boolean,
      default: false
    },
    dontHeader: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    headers: {
      type: [Array],
      default: () => []
    },
    opt: {
      type: [Object],
      default: () => {}
    },
    requestOpts: {
      type: [Object],
      default: () => {}
    },
    reload: {
      type: Boolean,
      default: false
    },
    totalItems: {
      type: Number,
      default: 0
    },
    select: {
      type: Number,
      default: 0
    },
    dontMode: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    field: {
      type: String
    },
    selected: {
      type: Array,
      default: null
    },
    dontColor: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: String
    },
    mode: {
      type: String
    },
    min: {
      type: Boolean,
      default: false
    },
    dontSearch: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showMode: this.$vuetify.breakpoint.xsOnly ? 'simple' : 'grid',
      pageIn: 1,
      perPageIn: 10,
      total: null,
      itemsInternal: [],
      loadingInternal: false,
      progress: 0,
      i: 0,
      confirmation: {
        title: '',
        text: '',
        color: 'red'
      }
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      (val) => {
        if (val === this.title) {
          this.getDefaultValues()
          this.$store.dispatch('confirmation/confirmationFinished', false)
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    dinamicTotal() {
      if (this.api === '' || this.total == null) {
        return {}
      }
      return { serverItemsLength: this.total }
    },
    permissions() {
      if (typeof this.app === 'object') {
        return this.app
      }
      const app = this.app.split('.')
      return {
        create:
          this.getPermissions([`${app[0]}.add_${app[1] ? app[1] : app[0]}`]) ||
          this.isAdmin,
        view:
          this.getPermissions([`${app[0]}.view_${app[1] ? app[1] : app[0]}`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([
            `${app[0]}.change_${app[1] ? app[1] : app[0]}`
          ]) || this.isAdmin,
        delete:
          this.getPermissions([
            `${app[0]}.delete_${app[1] ? app[1] : app[0]}`
          ]) || this.isAdmin
      }
    },
    query() {
      let query = this.$api
      this.api.split('.').forEach((element) => (query = query[element]))
      return query
    }
  },
  watch: {
    reload(val) {
      if (val) {
        this.getDefaultValues()
        this.$emit('update:reload', false)
      }
    },
    loading(val) {
      this.loadingInternal = val
    },
    page(val, old) {
      if (old !== val) {
        this.pageIn = val
      }
    },
    perPage(val, old) {
      if (old !== val) {
        this.perPageIn = val
      }
    },
    pageIn: {
      immediate: true,
      deeep: true,
      handler(val) {
        if (this.api !== '') {
          this.getDefaultValues()
        }
        this.$emit('update:page', val)
      }
    },
    perPageIn: {
      immediate: true,
      deeep: true,
      handler(val) {
        if (this.api !== '') {
          this.getDefaultValues()
        }
        this.$emit('update:perPage', val)
      }
    },
    items: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.api === '') {
          this.itemsInternal = val
        }
      }
    },
    api: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== '') {
          this.getDefaultValues()
        }
      }
    },
    opt: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.itemsInternal.length === 0) {
          this.progress = 0
        }
        if (
          this.itemsInternal.length > 0 &&
          this.i <= this.itemsInternal.length &&
          this.select > 0
        ) {
          this.progress =
            Math.round((this.i / this.select) * 100) > 100
              ? 100
              : Math.round((this.i / this.select) * 100)
          this.i = this.select > 0 ? this.i + 1 : 0
        }
        if (val !== null) {
          this.$emit('update:page', 1)
          this.getDefaultValues()
        }
      }
    },
    dontMode: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.showMode = 'list'
        }
      }
    },
    simple: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.showMode = 'simple'
        }
      }
    },
    mode: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val == 'cart') {
          this.showMode = 'grid'
        }
      }
    }
  },
  methods: {
    research() {
      this.pageIn = 1
      this.getDefaultValues()
    },
    changeMode() {
      switch (this.showMode) {
        case 'simple':
          this.showMode = 'list'
          this.$emit('changeShowMode', this.showMode)
          break
        case 'list':
          this.showMode = 'grid'
          this.$emit('changeShowMode', this.showMode)
          break
        case 'grid':
        default:
          this.showMode = 'simple'
          this.$emit('changeShowMode', this.showMode)
          break
      }
    },
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    async getDefaultValues() {
      try {
        this.loadingInternal = true
        const response = await this.query[this.method]({
          opt: {
            params: {
              page: this.pageIn,
              size: this.perPageIn,
              search: this.search,
              ...this.opt
            }
          },
          ...this.requestOpts
        })
        this.$set(
          this,
          'itemsInternal',
          response.data.results !== undefined
            ? response.data.results
            : response.data
        )
        this.$set(
          this,
          'total',
          response.data.results !== undefined
            ? Math.ceil(response.data.count / this.perPageIn)
            : null
        )
        this.$emit('update:totalItems', this.itemsInternal.length)
      } finally {
        this.loadingInternal = false
      }
    },
    remove(pk) {
      this.showConfirmation({
        show: true,
        model: this.title,
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.title,
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => {
          this.query[this.removeMethod]({ pk })
          this.$emit('wasRemove', true)
          this.$emit('deleted')
        }
      })
    }
  }
}
</script>
