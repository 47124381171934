export default {
  namespaced: true,
  state: {
    notifications: [],
    designer_notifications: []
  },
  getters: {
    getTotalSuborderNotifications: (state) => {
      return String(
        state.notifications.filter((notification) => notification.subOrder > 0)
          .length
      )
    },
    getTotalGeneralNotifications: (state) => {
      return String(
        state.notifications.filter(
          (notification) =>
            notification.subOrder === null ||
            notification.subOrder === undefined
        ).length
      )
    }
  },
  mutations: {
    ['ADD_NOTIFICATION'](state, notification) {
      state.notifications.push(notification)
    }
  },
  actions: {
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification)
    }
  }
}
