var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"open-on-click":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"offset-x":"30","offset-y":"10","color":"secondary","bottom":"","content":_vm.notificationCount > 0 ? _vm.notificationCount : '0'}},[_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"ridge 1px transparent"},attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.notificationIcon,"height":"32","width":"32"},on:{"click":function($event){return _vm.getNotifications(false)}}})],1)],1)]}}])},[_c('div',{staticStyle:{"max-height":"500px","/* Altura máxima del área de desplazamiento */\n      overflow-y":"auto","/* Habilitar scroll */\n      width":"400px"}},[(_vm.hasNotifications)?_c('v-list',{staticStyle:{"border":"ridge 1px transparent","padding":"0px"}},_vm._l((_vm.notificationsToShow),function(item,index){return _c('v-list-item',{key:index,class:{
          'has-messages-bell':
            item.content_type === 'message' && item.unread_messages > 0
        },style:({
          'background-color': item.color + ' !important',
          border: 'ridge 1px #DCDCDC',
          'font-size': '13px',
          width: '100%',
          color: item.statusDark || item.paid ? 'white' : 'black'
        }),attrs:{"text-color":"primary"},on:{"click":function($event){return _vm.open(item)}}},[(_vm.mode === 'events')?_c('v-list-item-title',{staticClass:"py-0",style:({
            'background-color': item.color + ' !important',
            border: 'ridge 1px transparent',
            'font-size': '13px',
            width: '100%',
            'margin-top': '10px',
            'margin-bottom': '10px',
            color: item.statusDark ? 'white' : 'black'
          }),attrs:{"dense":""}},[_c('v-list-item-subtitle',{staticClass:"mb-1 mt-2",style:({
              'font-size': '13px',
              color: item.statusDark ? 'white' : 'black'
            })},[_c('v-icon',{staticClass:"mr-3",staticStyle:{"color":"#345d48"},attrs:{"size":"15"}},[_vm._v(" fas fa-tag ")]),_c('b',[_vm._v(_vm._s(_vm.$tc('suborder', 1).toUpperCase())+":")])],1),_c('v-list-item-subtitle',{staticClass:"mb-1 mt-2",style:({
              'font-size': '13px',
              color: item.statusDark ? 'white' : 'black'
            })},[_c('b',[_vm._v("Código:")]),_vm._v(" "+_vm._s(item.code)+" ")]),_c('v-list-item-subtitle',{staticClass:"mb-1 mt-2",style:({
              'font-size': '13px',
              color: item.statusDark ? 'white' : 'black'
            })},[_c('b',[_vm._v("Nombre:")]),_vm._v(" "+_vm._s(item.title)+" ")]),(item.previousStatus)?_c('v-list-item-subtitle',{staticClass:"mb-1 mt-2",style:({
              'font-size': '13px',
              color: item.statusDark ? 'white' : 'black'
            })},[_c('b',[_vm._v(_vm._s(_vm.$t('previous_suborder_status'))+":")]),_vm._v(" "+_vm._s(_vm.$tc(_vm.formatStatus(item.previousStatus)))+" ")]):_vm._e(),(item.statusName)?_c('v-list-item-subtitle',{staticClass:"mb-1 mt-2",style:({
              'font-size': '13px',
              color: item.statusDark ? 'white' : 'black'
            })},[_c('b',[_vm._v(_vm._s(_vm.$t('current_status'))+":")]),_vm._v(" "+_vm._s(_vm.$tc(_vm.formatStatus(item.statusName)))+" ")]):_vm._e()],1):_vm._e(),(_vm.mode === 'notifications')?_c('v-list-item-title',{staticStyle:{"border":"ridge 1px transparent","font-size":"13px"}},[(item.content_type === 'message')?_c('div',[(item.unread_messages > 0)?_c('span',[_vm._v(" ("+_vm._s(item.unread_messages)+") ")]):_vm._e(),_c('v-icon',{staticClass:"mr-3",staticStyle:{"color":"#345d48"},attrs:{"size":"15"}},[_vm._v(" fas fa-comments ")]),_c('b',[_vm._v("CHAT:")]),_vm._v(" "+_vm._s(_vm.$t(item.title))+" "+_vm._s(item.details)+" "),_c('v-layout',{staticStyle:{"font-size":"11px","margin-top":"4px"},attrs:{"justify-end":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")])],1):_vm._e(),(item.content_type === 'maintenance')?_c('div',[_c('v-icon',{staticClass:"mr-3",staticStyle:{"color":"#345d48"},attrs:{"size":"15"}},[_vm._v(" fas fa-wrench ")]),_c('b',[_vm._v(_vm._s(_vm.$t(item.content_type).toUpperCase())+":")]),_vm._v(" "+_vm._s(item.title)+" "),_c('v-layout',{staticStyle:{"font-size":"11px","margin-top":"4px"},attrs:{"justify-end":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")])],1):_vm._e(),(item.content_type === 'expense')?_c('div',[_c('v-icon',{staticClass:"mr-3",staticStyle:{"color":"#345d48"},attrs:{"size":"15"}},[_vm._v(" fas fa-money-bill-alt ")]),_c('b',[_vm._v(_vm._s(_vm.$tc(item.content_type, 2).toUpperCase())+":")]),_vm._v(" "+_vm._s(item.title)+" "),_c('v-layout',{staticStyle:{"font-size":"11px","margin-top":"4px"},attrs:{"justify-end":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")])],1):_vm._e()]):_vm._e()],1)}),1):_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('no_notifications')))])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }