const path = 'dashboardOrders/'

const list = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const edit = ({ pk, form = {}, opt = {} }) =>
  axios.post(`${path}${pk}/`, form, opt)
const overview = ({ type, opt = {} }) => axios.get(`overview/${type}/`, opt)
const overview_designer = ({ opt = {} }) =>
  axios.get(`overview/designer_count/`, opt)
const logs = ({ opt = {} }) => axios.get(`auditlog/`, opt)

const path2 = 'typedate/'
const typedate = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

export default { list, edit, overview, overview_designer, logs, typedate }
