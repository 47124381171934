const path = 'inventory/'

const list = ({ opt = {} }) => axios.get(`${path}movements/`, opt)
const create = ({ form, opt = {} }) =>
  axios.post(`${path}movements/`, form, opt)
const edit = ({ pk, form, opt = {} }) =>
  axios.put(`${path}movements/${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}movements/${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}movements/${pk}/`)
const excel = ({ pk, params, opt = {} }) =>
  axios.get(`${path}getMovementsExcel/${pk}/${params}`, {
    ...opt,
    responseType: 'blob'
  })
const movementType = () => axios.get(`${path}movements/list_movements_type`)

export default {
  list,
  create,
  edit,
  show,
  remove,
  excel,
  movementType
}
