const path = 'quotation/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const softEdit = ({ pk, form, opt = {} }) =>
  axios.path(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const pdf = ({ pk, opt = {} }) =>
  axios.get(`getQuotePdf/${pk}/`, {
    ...opt,
    responseType: 'blob'
  })

export default { list, create, edit, softEdit, show, remove, pdf }
