<template>
  <v-menu open-on-click bottom offset-y>
    <!-- Activador del menú, que muestra la campana de notificaciones -->
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        offset-x="30"
        offset-y="10"
        color="secondary"
        bottom
        :content="notificationCount > 0 ? notificationCount : '0'"
      >
        <v-btn
          dark
          text
          v-bind="attrs"
          v-on="on"
          style="border: ridge 1px transparent"
        >
          <v-img
            @click="getNotifications(false)"
            :src="notificationIcon"
            height="32"
            width="32"
          >
          </v-img>
        </v-btn>
      </v-badge>
    </template>

    <!-- Contenedor para la lista de notificaciones con scroll -->
    <div
      style="
        max-height: 500px; /* Altura máxima del área de desplazamiento */
        overflow-y: auto; /* Habilitar scroll */
        width: 400px; /* Ancho fijo del contenedor */
      "
    >
      <!-- Lista de notificaciones si hay alguna -->
      <v-list
        v-if="hasNotifications"
        style="border: ridge 1px transparent; padding: 0px"
      >
        <v-list-item
          v-for="(item, index) in notificationsToShow"
          :key="index"
          @click="open(item)"
          text-color="primary"
          :class="{
            'has-messages-bell':
              item.content_type === 'message' && item.unread_messages > 0
          }"
          :style="{
            'background-color': item.color + ' !important',
            border: 'ridge 1px #DCDCDC',
            'font-size': '13px',
            width: '100%',
            color: item.statusDark || item.paid ? 'white' : 'black'
          }"
        >
          <!-- Notificaciones de subOrdenes -->
          <v-list-item-title
            class="py-0"
            dense
            v-if="mode === 'events'"
            :style="{
              'background-color': item.color + ' !important',
              border: 'ridge 1px transparent',
              'font-size': '13px',
              width: '100%',
              'margin-top': '10px',
              'margin-bottom': '10px',
              color: item.statusDark ? 'white' : 'black'
            }"
          >
            <v-list-item-subtitle
              class="mb-1 mt-2"
              :style="{
                'font-size': '13px',
                color: item.statusDark ? 'white' : 'black'
              }"
            >
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-tag
              </v-icon>
              <b>{{ $tc('suborder', 1).toUpperCase() }}:</b>
            </v-list-item-subtitle>
            <!-- Código la Sub-orden -->
            <v-list-item-subtitle
              class="mb-1 mt-2"
              :style="{
                'font-size': '13px',
                color: item.statusDark ? 'white' : 'black'
              }"
            >
              <b>Código:</b> {{ item.code }}
            </v-list-item-subtitle>
            <!-- Nombre de la Sub-orden -->
            <v-list-item-subtitle
              class="mb-1 mt-2"
              :style="{
                'font-size': '13px',
                color: item.statusDark ? 'white' : 'black'
              }"
            >
              <b>Nombre:</b> {{ item.title }}
            </v-list-item-subtitle>

            <!-- Estado anterior de la Sub-orden -->
            <v-list-item-subtitle
              v-if="item.previousStatus"
              class="mb-1 mt-2"
              :style="{
                'font-size': '13px',
                color: item.statusDark ? 'white' : 'black'
              }"
            >
              <b>{{ $t('previous_suborder_status') }}:</b>
              {{ $tc(formatStatus(item.previousStatus)) }}
            </v-list-item-subtitle>

            <!-- Estado actual de la Sub-orden -->
            <v-list-item-subtitle
              v-if="item.statusName"
              class="mb-1 mt-2"
              :style="{
                'font-size': '13px',
                color: item.statusDark ? 'white' : 'black'
              }"
            >
              <b>{{ $t('current_status') }}:</b>
              {{ $tc(formatStatus(item.statusName)) }}
            </v-list-item-subtitle>
          </v-list-item-title>

          <!-- Notificaciones de gastos, mantenimiento de equipos y chat -->
          <v-list-item-title
            v-if="mode === 'notifications'"
            style="border: ridge 1px transparent; font-size: 13px"
          >
            <!-- Notificaciones de chat -->
            <div v-if="item.content_type === 'message'">
              <span v-if="item.unread_messages > 0">
                ({{ item.unread_messages }})
              </span>
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-comments
              </v-icon>
              <b>CHAT:</b> {{ $t(item.title) }} {{ item.details }}
              <v-layout justify-end style="font-size: 11px; margin-top: 4px">
                {{ formatDate(item.date) }}
              </v-layout>
            </div>

            <!-- Notificaciones de mantenimientos -->
            <div v-if="item.content_type === 'maintenance'">
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-wrench
              </v-icon>
              <b>{{ $t(item.content_type).toUpperCase() }}:</b> {{ item.title }}
              <v-layout justify-end style="font-size: 11px; margin-top: 4px">
                {{ formatDate(item.date) }}
              </v-layout>
            </div>

            <!-- Notificaciones de gastos -->
            <div v-if="item.content_type === 'expense'">
              <v-icon class="mr-3" size="15" style="color: #345d48">
                fas fa-money-bill-alt
              </v-icon>
              <b>{{ $tc(item.content_type, 2).toUpperCase() }}:</b>
              {{ item.title }}
              <v-layout justify-end style="font-size: 11px; margin-top: 4px">
                {{ formatDate(item.date) }}
              </v-layout>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-title>{{ $t('no_notifications') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    mode: {
      type: String
    }
  },
  data() {
    return {
      notifications: [],
      current: 0
    }
  },
  computed: {
    ...mapGetters({
      getTotalSuborderNotifications:
        'notifications/getTotalSuborderNotifications',
      getTotalGeneralNotifications:
        'notifications/getTotalGeneralNotifications',
      isAdmin: 'session/isAdmin',
      me: 'session/me',
      company: 'company/getCompanyData'
    }),

    /*
     * notificationCount
     *
     * Calcula el conteo de notificaciones basándose en el modo actual.
     *
     * @returns {number} Número de notificaciones a mostrar.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    notificationCount() {
      const totalNotifications =
        this.mode === 'events'
          ? this.getTotalSuborderNotifications
          : this.getTotalGeneralNotifications
      const notificationsLength = this.notifications.length
      return totalNotifications - notificationsLength === 1
        ? totalNotifications
        : notificationsLength
    },

    /*
     * notificationsToShow
     *
     * Filtra las notificaciones para mostrar solo las únicas.
     *
     * @returns {Array} Notificaciones filtradas.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    notificationsToShow() {
      this.getSort()
      return this.mode === 'notifications'
        ? this.notifications.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.pk === value.pk)
          )
        : this.notifications
    },

    /*
     * hasNotifications
     *
     * Verifica si hay notificaciones disponibles.
     *
     * @returns {boolean} Verdadero si hay notificaciones, falso en caso
     * contrario.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    hasNotifications() {
      return this.notificationCount > 0
    },

    /*
     * notificationIcon
     *
     * Selecciona el ícono de notificación basado en el modo actual.
     *
     * @returns {string} Ruta del ícono de notificación.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    notificationIcon() {
      return this.mode === 'notifications'
        ? require('@/assets/color-icons/settings/notifications.png')
        : require('@/assets/color-icons/toolbar/notification.png')
    }
  },
  methods: {
    /*
     * formatStatus
     *
     * Formatea una cadena de estado reemplazando espacios y guiones por guiones
     * bajos.
     *
     * @param {string} status - La cadena de estado que se desea formatear.
     * Debe ser una cadena que pueda contener espacios o guiones.
     *
     * @returns {string} La cadena formateada, con espacios y guiones
     * reemplazados por guiones bajos.
     *
     * @author Ing. Argenis Osorio <argenisosorio10@gmail.com>
     */
    formatStatus(status) {
      // Reemplazar espacios y guiones con un solo guión bajo
      let formatted = status.replace(/[\s-]+/g, '_')

      // Eliminar guiones bajos dobles (opcional, por si acaso)
      formatted = formatted.replace(/_{2,}/g, '_')

      return formatted
    },

    /**
     * open
     *
     * Abre la ruta correspondiente al ítem dado según su tipo de contenido.
     *
     * La ruta se determina por el tipo de contenido del item:
     * - 'expense' redirige a 'expensesRecurring-pk'
     * - 'maintenance' redirige a 'maintenance-pk'
     * - 'message' redirige a 'sale'
     * - Cualquier otro tipo de contenido resulta en un nombre de ruta vacío.
     *
     * Ing. Rodrigo Boet (rudmanmrrod at gmail)
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    open(item) {
      this.current = item.id

      const currentRoute = this.$route

      if (currentRoute.name == 'sale') {
        if (item.content_type === 'message') {
          // Redirigir a una ruta temporal y luego regresar a /sale
          this.$router.replace({ path: '/temp' }).then(() => {
            this.$router
              .push({
                name: 'sale',
                params: {
                  pk: this.codification({ code: item.id }), // Pasando item.id.
                  details: item.details, // Pasando el código de la orden de venta.
                  openModal: true // Señal para abrir el modal de la suborden.
                }
              })
              .catch((err) => {})
          })
        } else if (item.content_type === 'order') {
          // Order de abrir la suborden específica
          /*
          this.$router.replace({ path: '/temp' }).then(() => {
            this.$router
              .push({
                name: 'sale',
                params: {
                  pk: this.codification({ code: item.id }), // Pasando item.pk.
                  details: item.code, // Pasando el código de la orden de venta.
                  openModal: true // Señal para abrir el modal de la suborden.
                }
              })
              .catch((err) => {})
          })
          */
        } else {
          this.$router.push({
            name:
              item.content_type === 'expense'
                ? 'expensesRecurring-pk'
                : item.content_type === 'maintenance'
                ? 'maintenance-pk'
                : '',
            params: {
              pk: this.codification({ code: item.id })
            }
          })
        }
      } else {
        if (item.content_type === 'message') {
          this.$router
            .push({
              name:
                item.content_type === 'expense'
                  ? 'expensesRecurring-pk'
                  : item.content_type === 'maintenance'
                  ? 'maintenance-pk'
                  : item.content_type === 'message'
                  ? 'sale'
                  : '',
              params: {
                pk: this.codification({ code: item.id }), // Pasando item.id.
                details: item.details, // Pasando el código de la orden de venta.
                openModal: true // Señal para abrir el modal de la suborden.
              }
            })
            .catch((err) => {})
        } else if (item.content_type === 'order') {
          // Order de abrir la suborden específica
          /*
          this.$router.replace({ path: '/temp' }).then(() => {
            this.$router
              .push({
                name: 'sale',
                params: {
                  pk: this.codification({ code: item.id }), // Pasando item.id.
                  details: item.code, // Pasando el código de la orden de venta.
                  openModal: true // Señal para abrir el modal de la suborden.
                }
              })
              .catch((err) => {})
          })
          */
        } else {
          this.$router.push({
            name:
              item.content_type === 'expense'
                ? 'expensesRecurring-pk'
                : item.content_type === 'maintenance'
                ? 'maintenance-pk'
                : '',
            params: {
              pk: this.codification({ code: item.id })
            }
          })
        }
      }
    },

    /**
     * formatDate
     *
     * Formatea la fecha según el formato configurado para la empresa.
     *
     * Ing. Rodrigo Boet (rudmanmrrod at gmail)
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    formatDate(date) {
      // Verifica si está configurado el formato de fecha en la empresa.
      if (this.company.format_date) {
        // Convierte el formato de la empresa a mayúsculas.
        let companyFormatDate = this.company.format_date.toUpperCase()

        // Si el formato de la empresa no incluye la hora, la concatenamos.
        if (!companyFormatDate.includes('HH:mm:ss')) {
          companyFormatDate += ' HH:mm:ss' // Agregamos la hora al formato.
        }

        // Formatea la fecha con el formato de la empresa (incluyendo la hora).
        return this.$moment(date).format(companyFormatDate)
      } else {
        // Formato de fecha y hora por defecto.
        return this.$moment(date).format('MM/DD/YYYY HH:mm:ss')
      }
    },

    /**
     * getSubOrderStatus
     *
     * Obtener el estatus actual de la sub-orden y el estado anterior.
     *
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    async getSubOrderStatus(id) {
      // Si el id no se recibe o es nulo, devolver una cadena vacía
      if (!id) {
        return ''
      }

      const url = `/orderProduct/${id}/suborders_notifications_logs/`

      try {
        const response = await axios.get(url)

        // Verificar si response.data es un array y no está vacío
        if (response.data && Array.isArray(response.data)) {
          // Ordenar los registros por "id" de forma descendente
          const sortedData = response.data.sort((a, b) => b.id - a.id)

          // Filtrar registros que tengan la clave "status" en su campo "changes"
          const filteredData = sortedData.filter((entry) => {
            try {
              const changes = JSON.parse(entry.changes)
              return changes.status
            } catch (e) {
              // Si hay un error al parsear, ignorar este registro
              return false
            }
          })

          // Obtener el último registro filtrado (el primero después de ordenar)
          const lastEntryWithStatus = filteredData[0]

          if (lastEntryWithStatus && lastEntryWithStatus.changes) {
            const changes = JSON.parse(lastEntryWithStatus.changes)
            // Último registro - Changes con la clave "status"
            return changes.status
          }
        }
      } catch (error) {
        // Si hay un error en la solicitud (por ejemplo, 404), continuar sin detenerse
        console.error('Error en la solicitud:', error.message)
      }

      // Si no se cumple ninguna condición, devolver una cadena vacía
      return ''
    },

    /**
     * getNotifications
     * Método para obtener y procesar las notificaciones de eventos.
     *
     * @param {boolean} fetchAndStore - Indica si se deben almacenar las
     * notificaciones en el store global.
     *
     * Detalles sobre la obtención de notificaciones:
     * - Se obtienen notificaciones desde hace un año hasta mañana.
     * - Se filtran las notificaciones según el modo actual y el usuario,
     * si no es administrador.
     * - Las notificaciones se formatean antes de ser almacenadas.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getNotifications(fetchAndStore) {
      const back_date = this.$moment()
        .subtract(365, 'days')
        .format('YYYY-MM-DD')
      const start_date = this.$moment()
        .subtract(15, 'days')
        .format('YYYY-MM-DD')
      const end_date = this.$moment().add(1, 'days').format('YYYY-MM-DD')

      const response = await this.$api.event.list({
        opt: {
          params: {
            date__back: back_date,
            date__gte: start_date,
            date__lte: end_date,
            mode: this.mode,
            user: this.isAdmin ? null : this.me.id
          }
        }
      })

      this.notifications = response.data
      this.getFormatNotification(response.data)

      if (fetchAndStore) {
        this.notifications.forEach((notification) => {
          this.$store.dispatch('notifications/addNotification', notification)
        })
      }
      this.getSort()
    },

    /**
     * getFormatNotification
     * Método para formatear las notificaciones de eventos.
     *
     * @param {Array} events - Lista de eventos a formatear.
     *
     * Detalles sobre el formato de las notificaciones:
     * - Gastos y mantenimiento de equipos se muestran con diferentes colores
     * según su estado.
     * - Filtros aplicados: fecha, modo, y usuario.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getFormatNotification(events) {
      let notifications = await Promise.all(
        events.map(async (event) => {
          let notification = {
            id:
              event.model === 'expense'
                ? event.expense
                : event.model === 'maintenance'
                ? event.maintenance
                : event.identifier,
            pk: event.pk,
            title: event.title,
            details: event.details,
            date: event.date,
            color:
              event.subOrder != undefined
                ? event.statusColor
                : event.background,
            app_label: event.app_label,
            content_type: event.model,
            statusName: event.statusName,
            previousStatus: event.previousStatus,
            statusDark: event.statusDark,
            paid: event.paid,
            code: event.code,
            order: event.order,
            subOrder: event.subOrder,
            promise_date: event.promise_date,
            type_dates: event.type_dates,
            unread_messages: event.unread_messages
          }

          if (event.model === 'order') {
            if (event.order) {
              // Pasarle al método el id de la sub-orden.
              const subOrderStatus = await this.getSubOrderStatus(event.order)
              // Agregar los estatus de la sub-orden al objeto notification.
              notification.subOrderStatus = subOrderStatus
            }
          }
          return notification
        })
      )
      this.notifications = notifications
    },

    /**
     * getSort
     * Método para ordenar las notificaciones almacenadas.
     *
     * Este método organiza las notificaciones de acuerdo a la cantidad de
     * mensajes no leídos y, en caso de empate, por el identificador primario
     * (pk).
     *
     * Detalles sobre el ordenamiento:
     * - Las notificaciones con más mensajes no leídos se muestran primero.
     * - Si dos notificaciones tienen la misma cantidad de mensajes no leídos,
     *   se ordenan por su identificador primario en orden descendente.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getSort() {
      this.notifications.sort(
        (a, b) => b.unread_messages - a.unread_messages || b.pk - a.pk
      )
    }
  },
  mounted() {
    this.getNotifications(true)
  }
}
</script>

<style>
.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 20px;
}

.suborder-style {
  width: 20%;
  overflow: hidden;
  box-sizing: border-box;
  border: ridge 1px transparent;
  margin-left: -25px;
}
</style>
