<template>
  <v-row row>
    <v-col xs="12" class="sm8 md10 p-r" order="2">
      <div class="chat-container" ref="chatContainer">
        <div>
          <v-btn
            text
            small
            block
            v-if="more_messages"
            class="text-capitalize caption"
            :loading="loading"
            :disabled="loading"
            @click="loadMore"
          >
            {{ $t('see_more') }}
          </v-btn>
          <div
            v-show="getPermissions([`chat.view_message`]) || isAdmin"
            class="message"
            v-for="(message, index) in messages"
            :key="index"
            :class="message.user == username ? 'own' : 'other'"
          >
            <div
              class="username"
              v-if="index > 0 && messages[index - 1].user != message.user"
            >
              {{ message.user }}
            </div>
            <div class="username" v-if="index == 0">{{ message.user }}</div>
            <div class="content">
              <div class="fs-16" v-html="message.content"></div>
              <div class="fs-10">
                {{ new Date(message.created_at).toLocaleString('en-US', {}) }}
              </div>
            </div>
            <div v-if="message.attachment.length > 0">
              <span v-for="(attch, index) in message.attachment" :key="index">
                <div class="content">
                  <a :href="attch.attachment" target="_blank">
                    <v-img
                      v-if="isImage(attch)"
                      :src="attch.attachment"
                      width="120"
                    />
                    <div v-else class="text-white" v-html="attch.name"></div>
                  </a>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="attach-chip"
        v-for="(attch, index) in chat_files"
        :key="index"
      >
        <v-chip color="green" close @click:close="removeAttch(index)">
          {{ attch.name }}
        </v-chip>
        <v-progress-circular
          v-if="chip_loader"
          class="ml-10"
          color="green"
          indeterminate
        ></v-progress-circular>
        <v-btn
          v-if="!chip_loader"
          class="ml-10"
          icon
          color="grey darken-1"
          @click="sendMessage"
          :disabled="disabled"
        >
          <v-icon :medium="!$vuetify.breakpoint.xsOnly">fa-paper-plane</v-icon>
        </v-btn>
      </div>
      <div class="typer border-input">
        <input
          class="ml-10"
          type="text"
          :placeholder="$t('write_a_message') + '...'"
          v-model="content"
          :disabled="disabled"
          @keydown.enter="sendMessage"
        />
        <input
          class="d-none"
          type="file"
          ref="image"
          multiple="true"
          :disabled="disabled"
          @change="onFilePicked"
        />
        <v-btn
          class="ml-10"
          icon
          color="grey darken-1"
          absolute
          @click.native="imageClick"
          :disabled="disabled"
        >
          <v-icon :medium="!$vuetify.breakpoint.xsOnly">fa-paperclip</v-icon>
        </v-btn>
        <v-btn
          class="mr-10"
          icon
          color="grey darken-1"
          right
          absolute
          @click="sendMessage"
          :disabled="disabled"
        >
          <v-icon :medium="!$vuetify.breakpoint.xsOnly">fa-paper-plane</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import '../../pages/chat/Chat.css'

export default {
  name: 'order-messages',
  props: {
    chat_id: Number
  },
  data() {
    return {
      messages: [],
      username: '',
      loader: null,
      loading: false,
      more_messages: false,
      current_chat: 0,
      content: '',
      user_id: '',
      disabled: false,
      chat_files: [],
      chat_info: [],
      chip_loader: false
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  },
  mounted() {
    this.user_id = this.me.id
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == 'SOCKET_ONMESSAGE') {
        this.checkMessage(mutation.payload)
      }
    })
  },
  watch: {
    chat_id: {
      immediate: true,
      handler(value) {
        if (value != 0 && value) {
          this.current_chat = value
          this.loadMessages(value)
          this.disabled = false
          this.setMessagesReaded()
        } else {
          this.disabled = true
        }
      }
    }
  },
  methods: {
    loadMessages(chat_pk) {
      this.disabled = false
      this.username = this.me.username
      this.messages = []
      this.$api.chat.product
        .list({
          opt: {
            params: {
              chat: chat_pk
            }
          }
        })
        .then((response) => {
          this.next = response.data.next
          this.checkMsg(this.next)
          let results = response.data.results.reverse()
          for (let message of results) {
            this.messages.push({
              user: message.username,
              content: message.comment,
              attachment: message.message_attch,
              created_at: message.created_at
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.disabled = true
        })
    },
    appendMessage(message) {
      this.messages.push({
        user: message.username,
        content: message.comment,
        attachment: message.message_attch,
        created_at: message.created_at
      })
    },
    loadMore() {
      axios
        .get(this.next, {
          params: {
            chat: this.current_chat
          }
        })
        .then((response) => {
          this.next = response.data.next
          this.checkMsg(this.next)
          let results = response.data.results.reverse()
          for (let message of results) {
            this.messages.unshift({
              user: message.username,
              content: message.comment,
              attachment: message.message_attch,
              created_at: message.created_at
            })
          }
        })
    },
    checkMsg(next) {
      if (next) {
        this.more_messages = true
      } else {
        this.more_messages = false
      }
    },

    /**
     * scrollChatToBottom
     *
     * Hace scroll hasta el final del contenedor del chat luego de enviar un
     * mensaje.
     *
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    scrollChatToBottom() {
      const targetChatContainer = this.$refs.chatContainer

      // Esperar hasta que el DOM se haya actualizado.
      this.$nextTick(() => {
        setTimeout(() => {
          if (targetChatContainer) {
            targetChatContainer.scrollTop = targetChatContainer.scrollHeight
          }
        }, 200)

        setTimeout(() => {
          if (targetChatContainer) {
            targetChatContainer.scrollTop = targetChatContainer.scrollHeight
          }
        }, 500)

        setTimeout(() => {
          if (targetChatContainer) {
            targetChatContainer.scrollTop = targetChatContainer.scrollHeight
          }
        }, 1000)
      })
    },

    /**
     * sendMessage
     *
     * Maneja el envío de mensajes, con o sin archivos adjuntos.
     *
     * Ing. Rodrigo Boet (rudmanmrrod at gmail)
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    sendMessage() {
      let chat = null

      // Determinar si hay archivos adjuntos.
      if (this.chat_files.length == 0) {
        // Verificar si el campo content está vacío
        if (this.content.trim() === '') {
          // Si está vacío, no hacer nada
          return
        }

        // Mensaje simple sin archivos adjuntos.
        chat = {
          chat: this.current_chat,
          user: this.user_id,
          comment: this.content,
          created_at: this.created_at
        }
      } else {
        // Mensaje con archivos adjuntos.
        chat = new FormData()
        chat.append('chat', this.current_chat)
        chat.append('user', this.user_id)
        chat.append('created_at', this.created_at)

        /* Determinar el contenido del comentario basado en la presencia de
         * texto y/o archivos adjuntos.
         */
        if (this.chat_files.length > 0) {
          // Si hay archivos adjuntos, verifica si hay contenido de texto.
          const comment =
            this.content !== '' ? this.content : this.chat_files[0].name
          chat.append('comment', comment)

          // Agrega todos los archivos adjuntos.
          for (let attch of this.chat_files) {
            chat.append('message_attch', attch)
          }
        } else {
          // Si no hay adjuntos enviar solo el texto.
          chat.append('comment', this.content)
        }
      }

      // Mostrar símbolo de carga mientras se envía el adjunto.
      this.chip_loader = true

      // Envío del mensaje al servidor a través de la API
      this.$api.chat.product
        .create({
          form: chat
        })
        .then((response) => {
          if (response.status == 201) {
            // Limpiar los datos locales.
            this.chat_files = []
            this.content = ''

            // Alerta de éxito al enviar mensaje.
            let text = `${this.$t('message')} ${this.$tc('send', 1)}`
            this.$toast.success(text)

            // Actualizar el estado global con el nuevo mensaje.
            this.$store.commit('socketMessage', {
              type: 'chat',
              content: response.data
            })

            // Cancelar símbolo de cargadel archivo adjunto.
            this.chip_loader = false

            // Hacer scroll hasta el final del chat.
            this.scrollChatToBottom()
          }
        })
        .catch((error) => {
          // Mensaje de error al usuario y registra el error
          this.$toast.error(this.$t('permissionnotfound'))
          console.error(error)
        })
    },

    imageClick() {
      this.$refs.image.click()
    },

    /**
     * onFilePicked
     *
     * Maneja la selección de archivos desde el input de tipo file.
     *
     * Ing. Rodrigo Boet (rudmanmrrod at gmail)
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    onFilePicked(e) {
      this.chat_files = []
      for (let item of e.target.files) {
        this.chat_files.push(item)
      }

      // Llamar al método del padre para hacer scroll.
      this.$emit('file-picked')
    },

    removeAttch(index) {
      this.chat_files.splice(index, 1)
    },
    checkMessage(message) {
      if (message.type == 'chat') {
        if (message.content.chat == this.current_chat) {
          const socket_message = message.content
          this.messages.push({
            user: socket_message.username,
            content: socket_message.comment,
            attachment: socket_message.message_attch,
            created_at: socket_message.created_at
          })
        }
      }
    },
    isImage(image) {
      const spliced = image.name.split('.')
      const format = spliced[spliced.length - 1]
      return ['png', 'jpg', 'jpeg'].includes(format)
    },
    setMessagesReaded() {
      this.$api.chat.product.markRead({ pk: this.current_chat })
    }
  }
}
</script>
