var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"open-on-click":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"offset-x":"30","offset-y":"10","color":"secondary","bottom":"","content":_vm.notifications.length == ''
            ? '0' // Muestra '0' si no hay notificaciones
            : _vm.notifications.length > 99
            ? '+99' // Muestra '+99' si hay más de 99 notificaciones
            : _vm.notifications.length}},[_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/color-icons/settings/users.png'),"height":"32","width":"32"},on:{"click":_vm.getDesignerNotification}})],1)],1)]}}])},[_c('div',{staticStyle:{"max-height":"500px","/* Altura máxima del área de desplazamiento */\n        overflow-y":"auto","/* Habilitar scroll */\n        width":"400px"}},[(_vm.notifications.length > 0)?_c('v-list',{staticStyle:{"border":"ridge 1px transparent","padding":"0px"}},_vm._l((_vm.notifications),function(item,index){return _c('v-list-item',{key:index,style:({
            width: '100%',
            border: 'ridge 1px #DCDCDC',
            padding: '0px'
          }),attrs:{"text-color":"primary"},on:{"click":function($event){return _vm.open(item)}}},[_c('v-col',{staticStyle:{"margin-bottom":"6px"}},[_c('v-icon',{staticClass:"mr-3",staticStyle:{"color":"#345d48"},attrs:{"size":"15"}},[_vm._v(" fas fa-user ")]),_c('b',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.$t('designerWorking').toUpperCase()))]),_c('v-list-item-subtitle',{staticClass:"mb-1 mt-2",staticStyle:{"font-size":"13px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('designer'))+":")]),_vm._v(" "+_vm._s(item.user_data.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--secondary mb-1",staticStyle:{"font-size":"13px"}},[_c('b',[_vm._v(_vm._s(_vm.$tc('suborder', 1))+":")]),_vm._v(" "+_vm._s(item.suborder_name)+" - "+_vm._s(item.suborder_number)+" ")]),_c('v-list-item-subtitle',{staticClass:"mb-1",staticStyle:{"font-size":"13px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('suborderstatus'))+":")]),_vm._v(" "+_vm._s(_vm.$t(item.suborder_status))+" ")]),_c('v-list-item-subtitle',{staticClass:"mb-1",staticStyle:{"font-size":"13px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('assignament_date'))+":")]),_vm._v(" "+_vm._s(_vm.formatDate(item.assignament_date))+" ")])],1)],1)}),1):_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('no_notifications')))])],1)],1)],1)]),_c('dialprod',{ref:"dialprod",on:{"prod":function($event){return _vm.getSuborder($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }