const path = 'orders/'

const list = ({ opt = {} }) => axios.get(path, opt)
const create = ({ form, opt = {} }) => axios.post(path, form, opt)
const edit = ({ pk, form, opt = {} }) => axios.put(`${path}${pk}/`, form, opt)
const show = ({ pk, opt = {} }) => axios.get(`${path}${pk}/`, opt)
const remove = ({ pk }) => axios.delete(`${path}${pk}/`)
const counter = ({ opt = {} }) => axios.get('orderCounter/', opt)
const removerepository = ({ pk, opt = {} }) =>
  axios.get(`removeRepository/${pk}/`, opt)

// endpoint cambiado (Se puede mejorar más)
const availableusers = ({ opt = {} }) => axios.get('users/availableusers/', opt)

// Extra values

const path2 = 'quantity/'
const quantity = {
  list: ({ opt = {} }) => axios.get(path2, opt),
  create: ({ form, opt = {} }) => axios.post(path2, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path2}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path2}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path2}${pk}/`)
}

const path3 = 'orderstatus/'
const status = {
  list: ({ opt = {} }) => axios.get(path3, opt),
  create: ({ form, opt = {} }) => axios.post(path3, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path3}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path3}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path3}${pk}/`),
  clone: ({ form, opt = {} }) => axios.post('cloneWorkflow/', form, opt),
  updatePosition: ({ form, opt = {} }) => axios.post('updatestatus/', form, opt)
}

const path4 = 'orderProduct/'
const product = {
  list: ({ opt = {} }) => axios.get(path4, opt),
  create: ({ form, opt = {} }) => axios.post(path4, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path4}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path4}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path4}${pk}/`),
  excel: ({ pk, params, opt = {} }) =>
    axios.get(`getSaleOrdersExcel/${pk}/${params}`, {
      ...opt,
      responseType: 'blob'
    })
}

const path5 = 'delivery/'
const delivery = {
  list: ({ opt = {} }) => axios.get(path5, opt),
  create: ({ form, opt = {} }) => axios.post(path5, form, opt),
  edit: ({ pk, form, opt = {} }) => axios.put(`${path5}${pk}/`, form, opt),
  show: ({ pk, opt = {} }) => axios.get(`${path5}${pk}/`, opt),
  remove: ({ pk }) => axios.delete(`${path5}${pk}/`)
}

export default {
  list,
  create,
  edit,
  show,
  remove,
  counter,
  removerepository,
  availableusers,
  quantity,
  status,
  product,
  delivery
}
